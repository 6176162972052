import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Popconfirm, Table, Row, Col, Upload, Modal, Spin, Select, Input } from 'antd';
import AWS from 'aws-sdk';
import { MdUpload } from 'react-icons/md';
import moment from 'moment';
import { get, raw, remove, save } from '../../System/Api/API';
import { useGlobal } from '../../System/Global/GlobalProvider';
import { PictureOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { unescapeNested } from '../../System/Global/unescapeArray';


export const FileViewer = () => {

    const [ form ] = Form.useForm();
    const [ uploading, setUploading ] = useState(false)
    const [ files, setFiles ] = useState([])
    const { campaign, UserData } = useGlobal();
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ isUploading, setIsUploading ] = useState(false);
    const [ toggleFile, setToggleFile ] = useState(false);
    const [ months, setMonths ] = useState(0)
    const [ selectedMonths, setSelectedMonths ] = useState("All")

    useEffect( () => {

        ( async () => {

            let start = dayjs(campaign.cm_created);
            let today = dayjs();

            let diff = today.diff('2024-04-01', 'month')

            setMonths(diff)


            await loadFiles();
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonths])

    const loadFiles = async () => {
        
        // let where = {fi_cm_id: campaign.cm_id}
        let _where = `WHERE (fi_cm_id = "${campaign.cm_id}")`

        if (selectedMonths !== "All") {
            // where = {
            //     fi_cm_id: campaign.cm_id, 
            //     'fi_created >=': dayjs(campaign.cm_created).add(selectedMonths, 'months'),
            //     'fi_created <': dayjs(campaign.cm_created).add(selectedMonths + 1, 'months')
            // }

            

            _where = `WHERE fi_cm_id = "${campaign.cm_id}" AND fi_month = ${selectedMonths}`
        }

        let _files = await raw(`SELECT files.*, CONCAT(users.us_first_name, ' ', users.us_last_name) AS username FROM files 
                        LEFT OUTER JOIN users ON users.us_id = files.fi_us_id
                        ${_where} 
                        ORDER BY fi_created DESC`)
        
        _files = unescapeNested(_files)
        
        // let _files = await get ( 
        //     'files', 
        //     where, 
        //     {fi_created: 'desc'},
        //     ["files.*, CONCAT(users.us_first_name, ' ', users.us_last_name) AS username"],
        //     [
        //         {
        //             table: "users", condition: "users.us_id = files.fi_us_id"
        //         }
        //     ]
        // )

        setFiles(_files)
        
        setUploading(false)
    }

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsArrayBuffer(file.originFileObj);
        });
   };

   AWS.config.update({
    region: 'eu-west-2',
    accessKeyId:        'AKIA5D7GVVL52WWGDJXK',
    secretAccessKey:    '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',
    });

    const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: { 
            Bucket: 'yellfiles' 
        },
    });


    const handleSubmit = async (values) => {

        setIsUploading(true)
   
        if (values.postFiles) {
            
            for await (let file of values.postFiles.fileList) {

                const fileContent = await readFileContent(file);
                const params = {
                    Key: file.name,
                    Body: fileContent,
                    ContentType: file.type,
                };
           
                const response = await s3.upload(params).promise();

                const s3Url = response.Location;

                await save('files',{
                    fi_name:            file.name,
                    fi_cm_id:           campaign.cm_id,
                    fi_us_id:           UserData.us_id,
                    fi_location:        s3Url,
                    fi_month:           values.month
                })
             }
        }

        
        setToggleFile(false);
        setIsUploading(false)
        setIsModalVisible(false)

        await loadFiles();

   }

    const deleteFile = async (fi_id) => {

        
        await remove('files', {fi_id: fi_id});
        await loadFiles();

    }

    const renderAddFile = () => {

        let _months = Array.from({ length: months }, (_, i) => months - i);

        return (

            months > 0 &&
            
                <div className="formArea">
                    
                <Form>
                    {
                        
                        <Row gutter={10}>
                            <Col xs={12}>
                                <h3>Campaign Files</h3>
                            </Col>
                            <Col xs={12}>
                                <div style={{display: "flex", justifyContent: "end"}}>
                                    <div style={{marginRight: 20, marginBottom: 10}}>
                                        <Select onChange={(v) => setSelectedMonths(v)} style={{width: 200}} defaultValue={"All"}>
                                        <Select.Option value={"All"}>All Months</Select.Option>
                                        {_months.map((m) => (
                                            <Select.Option key={m} value={m}>{`Month: ${((m) % 12)} (${dayjs('2024-04-01').add(m , 'months').format('MMM YY')}) `}</Select.Option>
                                        ))} 
                                        </Select>
                                    </div>

                                    <div style={{textAlign: 'right', marginBottom: 10}}>
                                        <Button onClick={() => setIsModalVisible(true)}><MdUpload/> Upload</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                    </Form>
                    
                    <Modal 
                        title={<div style={{textAlign: 'center', marginBottom: 20, paddingBottom: 20, borderBottom: '1px solid #f1f1f1'}}>Add files</div>}
                        width={600}
                        open={isModalVisible}
                        onCancel={() => { setIsModalVisible(false); setIsUploading(false) } }
                        footer={false}
                    >
                    
                        <Form form={form} onFinish={handleSubmit}>
                                
                                <Form.Item name="postFiles">
                                    <Upload.Dragger name="files" multiple={true}>
                                        <p className="ant-upload-drag-icon">
                                            <UploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload.
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                                
                                <Form.Item name="month" label="Month No." initialValue={_months[0]}>
                                    <Select style={{width: 200}}>
                                        {_months.map((m) => (
                                            <Select.Option key={m} value={m}>{`Month: ${((m) % 12)} (${m}) `}</Select.Option>
                                        ))} 
                                        </Select>
                                </Form.Item>
                                <Form.Item>
                                    { !isUploading ?
                                    <>
                                    
                                        
                                    </>
                                    : 
                                    
                                    <div style={{float: 'right'}}><Spin /> Uploading</div>
                                    }
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Save files</Button>
                                </Form.Item>
                        </Form>
                    
                    </Modal>
                </div>

            
        )
    }

    const cols = () => {

        return ([
            {
                title: 'File name',
                key: 'file name',
                render: item => item.fi_name
            },
            {
                title: 'Uploaded on',
                key: 'created',
                render: item => moment(item.fi_created).format('DD/MM/YYYY')
            },
            {
                title: 'Uploaded by',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: 'Month',
                render: item => {

                    return (
                        `Month: ${((+item.fi_month - 1) % 12) + 1} (${dayjs('2024-04-01').add(item.fi_month, 'months').format('MMM YY')}) `
                    )

                    // `Month: ${((+item.fi_month - 1) % 12) + 1} (${dayjs(campaign.cm_created).add(item.fi_month + 1, 'months').format('MMM YY')}) `,

                },
                key: 'month',
            },
            {
                title: '',
                key: 'actions',
                render: item => {
                    const sanitizedUrl = item.fi_location.replace('https://yellfiles.s3.eu-west-2.amazonaws.com', 'https://dgf48uysph6e0.cloudfront.net');
                    return (
                        <div style={{textAlign: 'right'}}>
                            <Popconfirm 
                                title="Are you sure to delete this file?"
                                onConfirm={() => deleteFile(item.fi_id)}
                            >
                                <Button type="danger" style={{marginRight: 5}}>Delete</Button>
                            </Popconfirm>
                            <Button onClick={() => window.open(sanitizedUrl)} type="primary">View</Button>
                        </div>
                    )
                }
            },
        ])
    }

    return (
        <div>
            <div className="fCard">
                {renderAddFile()}
                <Table dataSource={files} columns={cols()} rowKey={"fi_id"}/>
            </div>
        </div>
    )
    
    

}
